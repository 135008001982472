import React,{useEffect} from 'react';
import './App.css';
import Connect from './connect.js';


import { Web3Button } from '@web3modal/react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

const chains = [arbitrum, mainnet, polygon]
const projectId = 'YOUR_PROJECT_ID'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)




export default function App() {




  return (
    <div className="App">
      <header className="header___3lIJz">
        <div className="logo___3-F7Q">
          <a id="home">
          <img className="logoImage___3i5XT" src="./favicon.ico" alt="logo"/>JNTM</a>
        </div>

        <div className="nav___1H1Tj">
          <a href="#home">鸡</a>
          <a href="#about">你</a>
          <a href="#how">太</a>
          <a href="#token">美</a>

          <WagmiConfig config={wagmiConfig}>
          <Web3Button className="StyledButton-sc-k71ewl-0 iARtwl" scale="sm" width="148" height="40" />
          </WagmiConfig>


          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </div>
      </header>




    <Connect/>  




    <div className="about"></div>
    <div id='how'className="how"></div>














    </div>
  );
}


