import Tokencontract from './erc20abi.json';
import Aircontract from './airabi.json';
import Nftcontract from './nftabi.json';
import React,{useEffect,useState,useRef} from 'react';
import { useAccount} from 'wagmi'
import './App.css';
const Web3 = require('web3');
const { MerkleTree } = require('merkletreejs')
const keccak256 = require('keccak256')
/*
0x278725949C7d6fD3462843CBCF5992CEcf106991
Tokencontract,


0xc9e5b4D361Ca56838B07184A69d6f9a895f57a6B
Nftcontract,

0x6CfD77b417B1831d8EfE6BCDD04263ED58Fd776A
Aircontract
*/

export default function Connect() {
const web3 = new Web3(Web3.givenProvider || 'wss://eth-goerli.g.alchemy.com/v2/W-pGbVEElg1-IzgLloiwy8LoWEpJ6a2D');
const { address, isConnected } = useAccount();
const [Tokenbalanceof, setTokenbalanceof] = useState()
const [Inputa, setInputa] = useState()
const [Whitea, setWhite] = useState(whitelist)
const [Nftaddress, setNftaddress] = useState()
const [Inputvalue, setInputvalue] = useState()



const TokenaContract=new web3.eth.Contract(Tokencontract,"0x278725949C7d6fD3462843CBCF5992CEcf106991");
TokenaContract.methods.balanceOf('0x6CfD77b417B1831d8EfE6BCDD04263ED58Fd776A').call()
.then(function(result){
    setTokenbalanceof(web3.utils.fromWei(result))
});


    const Nftclaim=()=>{
        const NfttContract = new web3.eth.Contract(Nftcontract,"0xc9e5b4D361Ca56838B07184A69d6f9a895f57a6B"); 
        NfttContract.methods.reserveMintMiladys().send({from: address})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     }

    let refmount = useRef()

    useEffect(() => {
            if(Number(Inputvalue)==4){
                refmount.current=0.06*Number(Inputvalue)
            }
            else if(Number(Inputvalue)==3){
                refmount.current=0.07*Number(Inputvalue)
            }
            else if(Number(Inputvalue)==2){
               refmount.current=0.075*Number(Inputvalue)
            }
            else{
               refmount.current=0.08*Number(Inputvalue)
         };
     }, [Inputvalue]) 

    const Nftmint=()=>{
        const mounttoString =refmount.current+''
        console.log(mounttoString)
        const NfttContract = new web3.eth.Contract(Nftcontract,"0xc9e5b4D361Ca56838B07184A69d6f9a895f57a6B"); 
        NfttContract.methods.mintMiladys(Inputvalue).send({from: address, value: web3.utils.toWei(mounttoString,"ether")})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     } 



    const Aircontracts=()=>{
        const AIRRContract = new web3.eth.Contract(Aircontract,"0x6CfD77b417B1831d8EfE6BCDD04263ED58Fd776A"); 
         AIRRContract.methods.claimall().send({from: address})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     }
    const AirWhiteContracts=()=>{
        const leafNodes = Whitea.map(addr => keccak256(addr));  
        const merkletree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});
        const claimingAddr = keccak256(address)    
        const hexProof = merkletree.getHexProof(claimingAddr)

        const AIRRContract = new web3.eth.Contract(Aircontract,"0x6CfD77b417B1831d8EfE6BCDD04263ED58Fd776A"); 
        AIRRContract.methods.claim(hexProof).send({from: address})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     }
    const AirRoot=()=>{
        const leafNodes = Whitea.map(addr => keccak256(addr));  
        const merkletree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});
        const rootHash = '0x'+merkletree.getRoot().toString('hex');   


        const AIRRContract = new web3.eth.Contract(Aircontract,"0x6CfD77b417B1831d8EfE6BCDD04263ED58Fd776A"); 
        AIRRContract.methods.setMerkleRoot(rootHash).send({from: address})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     }

     const NftWhite=()=>{
        const NfttContract = new web3.eth.Contract(Nftcontract,"0xc9e5b4D361Ca56838B07184A69d6f9a895f57a6B"); 
        NfttContract.methods.editWhitelistTwo(Nftaddress).send({from: address})
        .on('error', function(error, receipt) { 
            console.log(error,receipt)
        })
        .on('transactionHash', function(hash){
            if (hash!=null){
            const interval = setInterval(()=>{
              console.log("Attempting to get transaction receipt...");
              web3.eth.getTransactionReceipt(hash, function(err, status){
                if (status) {
                  console.log(status);
                  alert("sucess");
                  clearInterval(interval);
                } else {
                  console.log(err);
                }
              });
            }, 1500); 
          }
        })
     }


	return (
		<div>

      <div id='home' className="maina">   
      <div className='drop' onClick={Nftclaim}>白名单免费领取NFT</div>
        <input type="text" className="puta" placeholder="输入NFT购买数量"  onChange={
        (evt)=>{
                setInputvalue(evt.target.value) 
        }



      }>

      </input>
      <button className='dropa' onClick={Nftmint}>
      购买NFT</button>
      <div className='drop' onClick={AirWhiteContracts}>白名单免费领取代币</div>
      <div className='drop' onClick={Aircontracts}>所有人免费领取代币</div>
      </div> 
      <div className="maina">
        <input type="text" className="puta" placeholder="输入NFT白名单地址" onChange={
        (evt)=>{
                setNftaddress([evt.target.value])                       
        }
      }>

      </input>
      <div className='drop' onClick={NftWhite}>上传新的NFT白名单</div>


      <input type="text" className="puta" placeholder="输入代币白名单地址" onChange={
        (evt)=>{
                 setInputa([evt.target.value])                 
        }
      }>

      </input>
      <button onClick={()=>{
        if (Inputa==undefined || Inputa=='') {
            console.log('null');

        }
        else{
            whitelist.push(Inputa[0])
            setWhite(whitelist)
        }

      }}>确定</button>
      <div className='drop' onClick={AirRoot}>上传新的代币白名单</div>




      </div>
      <div className="main_a">NFT---购买4个单价0.06ETH,购买3个单价0.07ETH,购买2个单价0.075ETH,购买1个单价0.08ETH</div>	

      <div>当前登录：{address}</div>
      <div>未领取代币数量：{Tokenbalanceof}</div>
	 </div>
	)
}
const whitelist=[
"0x17F6AD8Ef982297579C203069C1DbfFE4348c372",
"0x5c6B0f7Bf3E7ce046039Bd8FABdfD3f9F5021678",
"0xd7c5B8bEF900202e750014EE35Cd17838439F494",
"0x0a9b0579d3483d950920f6860e7179d026c34FC5",
"0xd0f35e56345AfBAa901Bf5DB374480ad31A89865",
"0xAcDE89978f31E6FfB6032246b400719556d7d3e7",
"0xdEdF2D93543934E5cD94bAcE869C7c86B3A0a262"
]